import { createFeatureSelector, createSelector } from '@ngrx/store';

import { InvoiceState, invoiceFeatureKey } from './invoice.reducer';

export const invoiceStateSelector = createFeatureSelector<InvoiceState>(
  invoiceFeatureKey
);

export const selectInvoiceState = createSelector(
  invoiceStateSelector,
  (state) => state
);

export const selectCurrentInvoice = createSelector(
  invoiceStateSelector,
  (state) => state.invoice
);

export const selectInvoiceSummary = createSelector(
  invoiceStateSelector,
  (state) => state.invoiceSummary
);

export const selectInvoiceSummaryRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.getInvoiceSummaryRequestStatus
);

export const selecAllInvoicePaymentsFiltered = createSelector(
  invoiceStateSelector,
  (state) => state.invoicePaymentsFilteredByPatient
);

export const selectAllInvoicePaymentsForAllPatients = createSelector(
  invoiceStateSelector,
  (state) => state.invoicePaymentsAllPatients
);

export const selectInvoicePaymentsForFilteredPatient = createSelector(
  invoiceStateSelector,
  (state) => state.invoicePaymentsFilteredByPatient
);

export const selectGenerateReceiptRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.generateReceiptRequestStatus
);

export const selectLoadedProviderList = createSelector(
  invoiceStateSelector,
  (state) => state.providerList
);

export const selectDuplicateInvoiceFlag = createSelector(
  invoiceStateSelector,
  (state) => state.selectDuplicateInvoice
);

export const selectPaymentPlanLoading = createSelector(
  invoiceStateSelector,
  (state) => state.paymentPlanLoading
);

export const selectPaymentPlanFailure = createSelector(
  invoiceStateSelector,
  (state) => state.createPaymentPlanError
);

export const selectPaymentPlanOptions = createSelector(
  invoiceStateSelector,
  (state) => state.createPaymentPlan
);

export const selectCreatePaymentPlan = createSelector(
  invoiceStateSelector,
  (state) => state.createPaymentPlan
);

export const selectSelectedPaymentPlanOption = createSelector(
  invoiceStateSelector,
  (state) => state.selectedPaymentPlanOption
);

export const selectSelectedInvoices = createSelector(
  invoiceStateSelector,
  (state) => state.selectedInvoices
);

export const selectPaymentAuthorizationRequest = createSelector(
  invoiceStateSelector,
  (state) => state.paymentAuthorizationRequest
);

export const selectPaymentPlanAuthorizationRequest = createSelector(
  invoiceStateSelector,
  (state) => state.paymentPlanAuthorizationRequest
);
export const selectPaymentAccountId = createSelector(
  invoiceStateSelector,
  (state) => state.paymentAuthorizationRequest.paymentAccountId
);

export const selectPaymentAuthorizationResponse = createSelector(
  invoiceStateSelector,
  (state) => state.paymentAuthorizationResponse
);

export const selectPaymentPlanAuthorizationResponse = createSelector(
  invoiceStateSelector,
  (state) => state.paymentPlanAuthorizationResponse
);

export const selectGetPaymentAuthorizationStatus = createSelector(
  invoiceStateSelector,
  (state) => ({
    getPaymentAuthorizationStatus: state.getPaymentAuthorizationStatus,
    paymentAuthorizationErrors: state.paymentAuthorizationErrors,
  })
);

export const selectInvoiceSearchParams = createSelector(
  invoiceStateSelector,
  (state) => state.invoiceSearchParams
);

export const selectFindInvoice = createSelector(
  invoiceStateSelector,
  (state) => state.findInvoice
);

export const selectFindInvoiceRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.findInvoiceRequestStatus
);

export const selectInvoiceSearchResponse = createSelector(
  invoiceStateSelector,
  (state) => ({
    getInvoiceRequestStatus: state.getInvoiceRequestStatus,
    getProviderListRequestStatus: state.getProviderListRequestStatus,
    queryForAccessionedOrderRequestStatus:
      state.queryForAccessionedOrderRequestStatus,
    selectDuplicateInvoice: state.selectDuplicateInvoice,
    queryForAccessionedOrderErrorResponse:
      state.queryForAccessionedOrderErrorResponse,
    invoice: state.invoice,
    queryForAccessionedOrderResponse: state.queryForAccessionedOrderResponse,
  })
);

export const selectGetPaymentAuthorizationTimeout = createSelector(
  invoiceStateSelector,
  (state) => state.paymentAuthorizationTimeout
);

export const selectPatientInvoiceRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.getPatientInvoiceRequestStatus
);

export const selectPayments = createSelector(
  invoiceStateSelector,
  (state) => state.payments
);

export const selectPaymentAuthorizationReceiptStatus = createSelector(
  invoiceStateSelector,
  (state) => state.getPaymentAuthorizationReceiptStatus
);

export const selectInvoicePdf = createSelector(
  invoiceStateSelector,
  (state) => state.invoicePdf
);

export const selectInvoicePdfRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.getInvoicePdfRequestStatus
);

export const selectTokenDetails = createSelector(
  invoiceStateSelector,
  (state) => state.tokenDetails
);

export const selectIsNewPaymentMethod = createSelector(
  invoiceStateSelector,
  (state) => state.isNewPaymentMethod
);

export const selectBraintreeTransactionOutputDetails = createSelector(
  invoiceStateSelector,
  (state) => state.braintreeTransactioOutputDetails
);

export const selectPaymentMethodDetails = createSelector(
  selectTokenDetails,
  selectBraintreeTransactionOutputDetails,
  selectIsNewPaymentMethod,
  (tokenDetails, braintreeTransactionSetupOutput, isNewPaymentMethod) => ({
    tokenDetails,
    braintreeTransactionSetupOutput,
    isNewPaymentMethod,
  })
);

export const selectUpdateInsuranceFailedResponse = createSelector(
  invoiceStateSelector,
  (state) => state.updateInsuranceRequestResponse
);

export const selectQueryAccessionedOrderResponse = createSelector(
  invoiceStateSelector,
  (state) => state.queryForAccessionedOrderResponse
);

export const selectQueryAccessionedOrderRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.queryForAccessionedOrderRequestStatus
);

export const selectQueryAccessionedOrderErrorResponse = createSelector(
  invoiceStateSelector,
  (state) => state.queryForAccessionedOrderErrorResponse
);

export const selectQueryAccessionedOrderRequestState = createSelector(
  selectQueryAccessionedOrderRequestStatus,
  selectQueryAccessionedOrderResponse,
  selectQueryAccessionedOrderErrorResponse,
  (status, res, err) => ({
    status,
    res,
    err,
  })
);

export const selectApplePaySupportFlag = createSelector(
  invoiceStateSelector,
  (state) => state.applePaySupportFlag
);

export const selectApplePaySupportFlagRequestStatus = createSelector(
  invoiceStateSelector,
  (state) => state.applePaySupportFlagRequestStatus
);

export const selectApplePaySupportFlagRequestError = createSelector(
  invoiceStateSelector,
  (state) => state.applePaySupportFlagRequestError
);

export const selectApplePaySupportFlagRequestState = createSelector(
  selectApplePaySupportFlagRequestStatus,
  selectApplePaySupportFlag,
  selectApplePaySupportFlagRequestError,
  (status, flag, err) => ({
    status,
    flag,
    err,
  })
);

export const selectInvoiceInsuranceList = createSelector(
  invoiceStateSelector,
  (state) => state.selectedInvoiceList
);
export const selectE2TextPaymentPlanLink = createSelector(
  invoiceStateSelector,
  (state) => state.email2TextPaymentPlanLink
);
export const selectBackToBillingLink = createSelector(
  invoiceStateSelector,
  (state) => state.backToBillingFlag
);
export const selectOriginalInvoice = createSelector(
  invoiceStateSelector,
  (state) => state.saveOriginalInvoice
);

export const invoiceQuery = {
  invoiceStateSelector,
  selectLoadedProviderList,
  selectSelectedInvoices,
  selecAllInvoicePaymentsFiltered,
  selectAllInvoicePaymentsForAllPatients,
  selectInvoicePaymentsForFilteredPatient,
  selectInvoiceSummary,
  selectInvoiceSummaryRequestStatus,
  selectCurrentInvoice,

  selectPayments,
  selectInvoicePdf,
  selectGetPaymentAuthorizationStatus,
  selectGetPaymentAuthorizationTimeout,
  selectPaymentAuthorizationRequest,
  selectPaymentAuthorizationReceiptStatus,
  selectInvoicePdfRequestStatus,
  selectTokenDetails,
  selectIsNewPaymentMethod,
  selectBraintreeTransactionOutputDetails,
  selectPaymentMethodDetails,
  selectQueryAccessionedOrderRequestState,
  selectApplePaySupportFlag,
  selectApplePaySupportFlagRequestStatus,
  selectApplePaySupportFlagRequestError,
  selectApplePaySupportFlagRequestState,
  selectInvoiceInsuranceList,

  selectPaymentPlanLoading,
  selectPaymentPlanFailure,
  selectPaymentPlanOptions,
  selectE2TextPaymentPlanLink,
  selectBackToBillingLink,
  selectOriginalInvoice,
};
