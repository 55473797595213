import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  BraintreeTransactionSetupOutput,
  IInvoiceSearch,
  IRequestError,
  IValidationErrors,
  InsuranceProvider,
  Invoice,
  InvoiceSummary,
  Payment,
  PaymentAuthorizationReceipt,
  PaymentAuthorizationRequest,
  PaymentAuthorizationResponse,
  PaymentInvoice,
  PaymentPlanAuthorizationRequest,
  PaymentPlanAuthorizationResponse,
  PaymentPlanOptions,
  PaymentPlanResponse,
  PaymentType,
  ProcessingInvoice,
  QueryAccessionedOrderResponse,
  TokenDetails,
  UpdateInsuranceFailedResponse,
} from '@patient-ui/shared/models';

import * as invoiceActions from './invoice.actions';

export const invoiceFeatureKey = 'invoice';

export type InvoiceState = {
  reasonCode: string;
  invoice: Invoice;
  invoiceSearchParams: IInvoiceSearch;
  findInvoice: Invoice;
  findInvoiceRequestStatus: RequestStatus;
  selectedInvoices: Invoice[];
  invoiceSummary: InvoiceSummary;
  invoicePaymentsFilteredByPatient: Payment[];
  invoicePaymentsAllPatients: Payment[];
  generateReceiptRequestStatus: RequestStatus;
  serviceUnavailable: boolean;
  getInvoiceRequestStatus: RequestStatus;
  getPatientInvoiceRequestStatus: RequestStatus;
  getInvoicePdfRequestStatus: RequestStatus;
  getInvoiceSummaryRequestStatus: RequestStatus;
  updateInsuranceRequestStatus: RequestStatus;
  updateInsuranceRequestResponse: UpdateInsuranceFailedResponse;
  queryForAccessionedOrderRequestStatus: RequestStatus;
  queryForAccessionedOrderResponse: QueryAccessionedOrderResponse | null;
  queryForAccessionedOrderErrorResponse: IRequestError | null;
  getProviderListRequestStatus: RequestStatus;
  providerList: InsuranceProvider[];
  processingInvoices: ProcessingInvoice[];
  getProcessingInvoicesStatus: RequestStatus;
  paymentAuthorizationRequest: PaymentAuthorizationRequest;
  paymentAuthorizationResponse: PaymentAuthorizationResponse;
  paymentPlanAuthorizationRequest: PaymentPlanAuthorizationRequest;
  paymentPlanAuthorizationResponse: PaymentPlanAuthorizationResponse;
  getPaymentAuthorizationStatus: RequestStatus;
  paymentAuthorizationErrors: IValidationErrors | undefined;
  paymentAuthorizationTimeout: boolean;
  paymentAuthorizationReceipt: PaymentAuthorizationReceipt;
  getPaymentAuthorizationReceiptStatus: RequestStatus;
  payments: PaymentInvoice[];
  invoicePdf: Blob | undefined;
  tokenDetails: TokenDetails;
  // isNewPaymentMethod === undefined => unknown flow (not sure if we are in GBP flow or RUBP flow)
  // isNewPaymentMethod === null      => GBP flow
  // isNewPaymentMethod === true      => RUBP flow + reg. user is using new payment method (not one of the save options)
  // isNewPaymentMethod === false     => RUBP flow + reg. user is using one of their saved payment method options
  isNewPaymentMethod: boolean | null | undefined;
  selectDuplicateInvoice: boolean;
  braintreeTransactioOutputDetails?: BraintreeTransactionSetupOutput;
  applePaySupportFlag: string | null;
  applePaySupportFlagRequestStatus: RequestStatus;
  applePaySupportFlagRequestError: IRequestError | null;
  selectedInvoiceList: string[];
  paymentPlanLoading: boolean;
  createPaymentPlan: PaymentPlanResponse;
  createPaymentPlanError: boolean;
  selectedPaymentPlanOption?: PaymentPlanOptions;
  email2TextPaymentPlanLink: boolean;
  backToBillingFlag: boolean;
  saveOriginalInvoice: Invoice;
};

export const initialState: InvoiceState = {
  reasonCode: '',
  invoice: {
    number: '',
    zipCode: '',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    specimenNumber: '',
    dateOfService: '',
    invoiceDate: '',
    documentKey: '',
    patientName: '',
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
      middleName: '',
      prefix: '',
      orgName: '',
      practiceLocationAddress: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    totalCharges: 0,
    adjustments: 0,
    medicarePaid: 0,
    medicaidPaid: 0,
    insurancePaid: 0,
    patientPaid: 0,
    dunningCycleCode: '',
    orderingPhyAccountNumber: '',
    patientInvoiceDetail: {
      invoiceNumber: '',
      accountNumber: '',
      accountNpi: '',
      taxId: '',
      mailToBarcode: '',
      participateZip: '',
      billCode: '',
      billDate: '',
      billTo: {
        firstName: '',
        lastName: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: {
            code: '',
          },
        },
        phone: '',
      },
      dunning: {
        number: null,
        suffix: '',
        specialDunning: '',
        specialCategory: '',
        message: [''],
      },
      specimenNumber: '',
      specimenDate: '',
      patient: {
        lastName: '',
        firstName: '',
        dateOfBirth: '',
        gender: '',
        relationToSubscriber: '',
      },
      physician: {
        name: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: {
            code: '',
          },
        },
      },
      lab: {
        code: '',
        name: '',
        brandCode: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: {
            code: '',
          },
        },
      },
      orderedTests: [
        {
          testNumber: '',
          testType: '',
        },
        {
          testNumber: '',
          testType: '',
        },
      ],
      diagnosisCodes: [''],
      invoiceTests: [
        {
          testName: '',
          price: '',
        },
        {
          testName: '',
          price: '',
        },
      ],
      amount: {
        total: {
          payments: '',
          adjustments: '',
          pcDiscount: '',
          medicare: {
            billed: '',
            payments: '',
            denials: '',
            deduction: '',
          },
          medicaid: {
            billed: '',
            payments: '',
            deduction: '',
          },
          insurance: {
            payments: '',
          },
        },
        balance: {
          charges: '',
          adjustments: '',
          insurance: '',
          patient: '',
          due: '',
          medicareMedicaidTotal: '',
        },
      },
      ocr: {
        specimenNumber: '',
        amount: '',
        checkDigit: null,
      },
      insurance: {
        payments: '',
      },
      remit: {
        companyName: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: null,
        },
        phoneNumber: '',
        web: '',
      },
      drawLocation: '',
    },
  },
  invoiceSearchParams: {
    zipCode: '',
  },
  findInvoice: {
    number: '',
    zipCode: '',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    specimenNumber: '',
    dateOfService: '',
    invoiceDate: '',
    documentKey: '',
    patientName: '',
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
      middleName: '',
      prefix: '',
      orgName: '',
      practiceLocationAddress: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    totalCharges: 0,
    adjustments: 0,
    medicarePaid: 0,
    medicaidPaid: 0,
    insurancePaid: 0,
    patientPaid: 0,
    dunningCycleCode: '',
    orderingPhyAccountNumber: '',
  },
  generateReceiptRequestStatus: RequestStatus.NotSent,
  findInvoiceRequestStatus: RequestStatus.NotSent,
  selectedInvoices: [],
  invoiceSummary: {
    collectionsInvoices: [],
    currentInvoices: [],
    pastDueInvoices: [],
    paymentHistory: [],
  },
  invoicePaymentsFilteredByPatient: [],
  invoicePaymentsAllPatients: [],
  serviceUnavailable: false,
  getInvoiceRequestStatus: RequestStatus.NotSent,
  getPatientInvoiceRequestStatus: RequestStatus.NotSent,
  updateInsuranceRequestStatus: RequestStatus.NotSent,
  updateInsuranceRequestResponse: {
    code: '',
    description: '',
  },
  queryForAccessionedOrderRequestStatus: RequestStatus.NotSent,
  queryForAccessionedOrderResponse: null,
  queryForAccessionedOrderErrorResponse: null,

  getProviderListRequestStatus: RequestStatus.NotSent,
  getInvoiceSummaryRequestStatus: RequestStatus.NotSent,
  providerList: [],
  processingInvoices: [],
  getProcessingInvoicesStatus: RequestStatus.NotSent,

  paymentAuthorizationRequest: {
    accountId: '',
    paymentAccountId: '',
    paymentMethod: PaymentType.CREDIT,
    paymentDate: '',
    firstName: '',
    lastName: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    invoiceList: [],
    emailAddress: '',
    isGuestUser: true,
    browser: '',
    mobile: false,
  },
  paymentAuthorizationResponse: {
    successful: false,
    encryptedLpid: null,
    transactionId: '',
    encryptedTransactionId: '',
    reasonCode: '',
    optedIn: false,
  },
  paymentPlanAuthorizationRequest: {
    accountBalance: 0,
    patientEmailAddress: '',
    planFrequency: '',
    paymentSource: '',
    billType: '',
    invoiceDetails: [
      {
        balance: 0,
        lcaInvoiceNumber: '',
        specimenNumber: '',
        dateOfService: '',
      },
    ],
    frequencyCount: 0,
    amountPerPayment: 0,
    paymentDetails: {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      cityName: '',
      stateCode: '',
      postalCode: '',
      paymentType: '',
      accountId: '',
      expirationYear: 0,
      expirationMonth: 0,
      token: '',
    },
  },
  paymentPlanAuthorizationResponse: {
    paymentPlanId: '',
    reasonCode: '',
    reasonDescription: '',
    warnings: [],
    transactionId: '',
    invoiceDetails: [],
    accountBalance: 0,
    successful: null,
    encryptedLpid: '',
    encryptedTransactionId: '',
  },
  getPaymentAuthorizationStatus: RequestStatus.NotSent,
  paymentAuthorizationErrors: undefined,
  paymentAuthorizationTimeout: false,
  payments: [],
  paymentAuthorizationReceipt: {
    isSuccessful: false,
    transactionId: '',
  },
  getPaymentAuthorizationReceiptStatus: RequestStatus.NotSent,
  getInvoicePdfRequestStatus: RequestStatus.NotSent,
  invoicePdf: undefined,
  tokenDetails: {
    token: '',
    paymentType: 'CARD',
    debitLast4: '',
    expirationDate: '',
    cardType: '',
    cardBrand: '',
    accountType: '',
    routingNumber: '',
  },
  isNewPaymentMethod: undefined,
  selectDuplicateInvoice: false,
  braintreeTransactioOutputDetails: null,
  applePaySupportFlag: null,
  applePaySupportFlagRequestStatus: RequestStatus.NotSent,
  applePaySupportFlagRequestError: null,
  selectedInvoiceList: [],
  paymentPlanLoading: false,
  createPaymentPlan: {},
  createPaymentPlanError: false,
  email2TextPaymentPlanLink: false,
  backToBillingFlag: false,
  saveOriginalInvoice: {
    number: '',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '',
      line2: undefined,
      city: '',
      state: '',
      zipCode: '',
    },
    pid: null,
    specimenNumber: '',
    dateOfService: '',
    invoiceDate: '',
    physicianDetail: undefined,
    totalCharges: null,
    adjustments: null,
    medicarePaid: null,
    medicaidPaid: null,
    insurancePaid: null,
    patientPaid: null,
    orderingPhyAccountNumber: '',
  },
};

export const invoiceReducer = createReducer(
  initialState,
  on(invoiceActions.addInvoice, (state, { payload }) => ({
    ...state,
    selectedInvoices: state.selectedInvoices
      .filter((value: Invoice) => value.number !== payload.number)
      .concat([payload]),
  })),

  on(invoiceActions.saveOriginalInvoice, (state, { payload }) => ({
    ...state,
    invoice: {
      ...state.invoice,
      ...payload,
    },
  })),

  on(invoiceActions.addPayment, (state, { payload }) => ({
    ...state,
    payments: state.payments
      .filter((value) => value.invoiceNumber !== payload.invoiceNumber)
      .concat([payload]),
  })),
  on(invoiceActions.addSelectedPaymentList, (state, { payload }) => ({
    ...state,
    selectedInvoices: payload,
  })),
  on(invoiceActions.clearAllSelectedPaymentsAndInvoices, (state) => ({
    ...state,
    selectedInvoices: initialState.selectedInvoices,
    payments: initialState.payments,
    invoiceSearchParams: initialState.invoiceSearchParams,
  })),
  on(invoiceActions.clearFindInvoiceStatus, (state) => ({
    ...state,
    findInvoiceRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.clearInvoicePdf, (state) => ({
    ...state,
    invoicePdf: undefined,
    getInvoicePdfRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.clearInvoiceSearchResult, (state) => ({
    ...state,
    invoice: { ...initialState.invoice },
    getInvoiceRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.clearPaymentProcessOnConfirmation, (state) => ({
    ...state,
    selectedInvoices: initialState.selectedInvoices,
    payments: initialState.payments,
    paymentAuthorizationRequest: initialState.paymentAuthorizationRequest,
    paymentAuthorizationResponse: initialState.paymentAuthorizationResponse,
    paymentPlanAuthorizationRequest:
      initialState.paymentPlanAuthorizationRequest,
    paymentPlanAuthorizationResponse:
      initialState.paymentPlanAuthorizationResponse,
    getPaymentAuthorizationStatus: initialState.getPaymentAuthorizationStatus,
    paymentAuthorizationReceipt: initialState.paymentAuthorizationReceipt,
    getPaymentAuthorizationReceiptStatus:
      initialState.getPaymentAuthorizationReceiptStatus,
  })),
  on(invoiceActions.clearTokenData, (state) => ({
    ...state,
    tokenDetails: initialState.tokenDetails,
  })),
  on(invoiceActions.deletePayment, (state, { payload }) => ({
    ...state,
    payments: state.payments.filter(
      (value) => value.invoiceNumber !== payload.invoiceNumber
    ),
  })),
  on(invoiceActions.duplicateInvoice, (state, { payload }) => ({
    ...state,
    selectDuplicateInvoice: payload,
  })),
  on(invoiceActions.findInvoice, (state, { payload }) => ({
    ...state,
    findInvoiceRequestStatus: RequestStatus.Pending,
    invoiceSearchParams: payload,
  })),
  on(invoiceActions.findInvoiceFailure, (state, { payload }) => ({
    ...state,
    findInvoiceRequestStatus: payload,
  })),
  on(invoiceActions.findInvoiceSuccess, (state, { payload }) => ({
    ...state,
    findInvoice: {
      ...payload,
    },
    findInvoiceRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.generateReceipt, (state) => ({
    ...state,
    generateReceiptRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.generateReceiptFailure, (state) => ({
    ...state,
    generateReceiptRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.generateReceiptReset, (state) => ({
    ...state,
    generateReceiptRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.generateReceiptSuccess, (state) => ({
    ...state,
    generateReceiptRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.getInvoice, (state, { payload }) => ({
    ...state,
    getInvoiceRequestStatus: RequestStatus.Pending,
    invoiceSearchParams: payload,
  })),
  on(invoiceActions.getInvoiceFailure, (state, { payload }) => ({
    ...state,
    getInvoiceRequestStatus: payload,
  })),
  on(invoiceActions.getInvoiceSuccess, (state, { payload }) => ({
    ...state,
    invoice: {
      ...payload,
    },
    getInvoiceRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.clearPatientInvoice, (state) => ({
    ...state,
    getPatientInvoiceRequestStatus: RequestStatus.NotSent,
    invoiceSearchParams: initialState.invoiceSearchParams,
  })),
  on(invoiceActions.getPatientInvoice, (state) => ({
    ...state,
    getPatientInvoiceRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getPatientInvoiceFailure, (state) => ({
    ...state,
    getPatientInvoiceRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getPatientInvoiceSuccess, (state, { payload }) => ({
    ...state,
    getPatientInvoiceRequestStatus: RequestStatus.Success,
    invoice: {
      ...payload,
    },
  })),
  on(invoiceActions.createPaymentPlan, (state) => ({
    ...state,
    paymentPlanLoading: true,
    createPaymentPlanError: false,
  })),
  on(invoiceActions.createPaymentPlanFailure, (state) => ({
    ...state,
    createPaymentPlanError: true,
    paymentPlanLoading: false,
  })),
  on(invoiceActions.createPaymentPlanSuccess, (state, { response }) => ({
    ...state,
    createPaymentPlanError: response.reasonCode !== '200',
    createPaymentPlan: {
      ...response,
    },
    paymentPlanLoading: false,
  })),
  on(invoiceActions.getInvoicePdf, (state) => ({
    ...state,
    getInvoicePdfRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getInvoicePdfFailure, (state) => ({
    ...state,
    getInvoicePdfRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getInvoicePdfSuccess, (state, { payload }) => ({
    ...state,
    invoicePdf: payload,
    getInvoicePdfRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.getInvoiceSummary, (state) => ({
    ...state,
    getInvoiceSummaryRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getInvoiceSummaryFailure, (state) => ({
    ...state,
    getInvoiceSummaryRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getInvoiceSummarySuccess, (state, { payload }) => ({
    ...state,
    invoiceSummary: {
      ...payload,
    },
    getInvoiceSummaryRequestStatus: RequestStatus.Success,
  })),

  on(invoiceActions.getPaymentAuthorization, (state, { payload }) => ({
    ...state,
    paymentAuthorizationRequest: payload,
    getPaymentAuthorizationStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getPaymentPlanAuthorization, (state, { payload }) => ({
    ...state,
    paymentPlanAuthorizationRequest: payload,
    getPaymentAuthorizationStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getPaymentAuthorizationFailure, (state, { errors }) => ({
    ...state,
    getPaymentAuthorizationStatus: RequestStatus.Failure,
    paymentAuthorizationErrors: errors,
  })),
  on(invoiceActions.getPaymentAuthorizationReset, (state) => ({
    ...state,
    getPaymentAuthorizationStatus: RequestStatus.NotSent,
    paymentAuthorizationResponse: initialState.paymentAuthorizationResponse,
    paymentAuthorizationTimeout: initialState.paymentAuthorizationTimeout,
  })),

  on(
    invoiceActions.getPaymentPlanAuthorizationSuccess,
    (state, { payload }) => ({
      ...state,
      paymentPlanAuthorizationResponse: payload,
      getPaymentAuthorizationStatus: RequestStatus.Success,
      tokenDetails: initialState.tokenDetails,
    })
  ),
  on(invoiceActions.getPaymentAuthorizationSuccess, (state, { payload }) => ({
    ...state,
    paymentAuthorizationResponse: payload,
    getPaymentAuthorizationStatus: RequestStatus.Success,
    tokenDetails: initialState.tokenDetails,
  })),

  on(invoiceActions.getPaymentAuthorizationTimeout, (state, { payload }) => ({
    ...state,
    paymentAuthorizationTimeout: payload,
  })),
  on(invoiceActions.getPaymentAuthorizationReceipt, (state) => ({
    ...state,
    paymentAuthorizationReceipt: {
      ...initialState.paymentAuthorizationReceipt,
    },
  })),
  on(invoiceActions.getPaymentAuthorizationReceiptFailure, (state) => ({
    ...state,
    getPaymentAuthorizationReceiptStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getPaymentAuthorizationReceiptReset, (state) => ({
    ...state,
    paymentAuthorizationReceipt: {
      ...initialState.paymentAuthorizationReceipt,
    },
  })),
  on(invoiceActions.getPaymentAuthorizationReceiptSuccess, (state) => ({
    ...state,
    getPaymentAuthorizationReceiptStatus: RequestStatus.Success,
  })),
  on(invoiceActions.getProcessingInvoices, (state) => ({
    ...state,
    getProcessingInvoicesStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getProcessingInvoicesFailure, (state) => ({
    ...state,
    getProcessingInvoicesStatus: RequestStatus.Failure,
    processingInvoices: { ...initialState.processingInvoices },
  })),
  on(invoiceActions.getProcessingInvoicesSuccess, (state, { payload }) => ({
    ...state,
    getProcessingInvoicesStatus: RequestStatus.Success,
    processingInvoices: payload,
  })),
  on(invoiceActions.getProviderList, (state) => ({
    ...state,
    getProviderListRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.getProviderListFailure, (state) => ({
    ...state,
    getProviderListRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.getProviderListReset, (state) => ({
    ...state,
    getProviderListRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.getProviderListSuccess, (state, { payload }) => ({
    ...state,
    providerList: {
      ...payload,
    },
    getProviderListRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.populateInvoicePaymentsAll, (state, { payload }) => ({
    ...state,
    invoicePaymentsAllPatients: payload,
  })),
  on(invoiceActions.populateInvoicePaymentsFiltered, (state, { payload }) => ({
    ...state,
    invoicePaymentsFilteredByPatient: payload,
  })),
  on(invoiceActions.removeInvoice, (state, { payload }) => ({
    ...state,
    selectedInvoices: state.selectedInvoices.filter(
      (value) => value.number !== payload.number
    ),
  })),
  on(invoiceActions.resetState, () => ({ ...initialState })),
  on(invoiceActions.savePaymentData, (state, { payload }) => ({
    ...state,
    paymentAuthorizationRequest: payload,
  })),
  on(invoiceActions.savePaymentPlanData, (state, { payload }) => ({
    ...state,
    paymentPlanAuthorizationRequest: payload,
  })),
  on(invoiceActions.saveTokenData, (state, { payload }) => ({
    ...state,
    tokenDetails: payload,
  })),
  on(invoiceActions.setNewPaymentMethod, (state, { isNewPaymentMethod }) => ({
    ...state,
    isNewPaymentMethod,
  })),
  on(invoiceActions.saveBraintreeTransactionOutput, (state, { payload }) => ({
    ...state,
    braintreeTransactioOutputDetails: payload,
  })),
  on(invoiceActions.resetBraintreeTransactionOutput, (state) => ({
    ...state,
    braintreeTransactioOutputDetails: null,
  })),
  on(invoiceActions.updateInsurance, (state) => ({
    ...state,
    updateInsuranceRequestStatus: RequestStatus.Pending,
  })),
  on(invoiceActions.updateInsuranceFailure, (state) => ({
    ...state,
    updateInsuranceRequestResponse: {
      code: '',
      description: '',
    },
    updateInsuranceRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.updateInsuranceEVFailure, (state, { payload }) => ({
    ...state,
    updateInsuranceRequestResponse: payload,
    updateInsuranceRequestStatus: RequestStatus.Failure,
  })),
  on(invoiceActions.updateInsuranceReset, (state) => ({
    ...state,
    updateInsuranceRequestStatus: RequestStatus.NotSent,
  })),
  on(invoiceActions.updateInsuranceSuccess, (state) => ({
    ...state,
    updateInsuranceRequestStatus: RequestStatus.Success,
  })),
  on(invoiceActions.queryForAccessionedOrder, (state) => ({
    ...state,
    queryForAccessionedOrderRequestStatus: RequestStatus.Pending,
    queryForAccessionedOrderResponse: null,
    queryForAccessionedOrderErrorResponse: null,
  })),
  on(invoiceActions.queryForAccessionedOrderSuccess, (state, { response }) => ({
    ...state,
    queryForAccessionedOrderRequestStatus: RequestStatus.Success,
    queryForAccessionedOrderResponse: response,
    queryForAccessionedOrderErrorResponse: null,
  })),
  on(invoiceActions.queryForAccessionedOrderFailure, (state, { error }) => ({
    ...state,
    queryForAccessionedOrderRequestStatus: RequestStatus.Failure,
    queryForAccessionedOrderResponse: null,
    queryForAccessionedOrderErrorResponse: error,
  })),
  on(invoiceActions.getApplePaySupportFlag, (state) => ({
    ...state,
    applePaySupportFlag: null,
    applePaySupportFlagRequestStatus: RequestStatus.Pending,
    applePaySupportFlagRequestError: null,
  })),
  on(invoiceActions.getApplePaySupportFlagSuccess, (state, { response }) => ({
    ...state,
    applePaySupportFlag: response.flagValue,
    applePaySupportFlagRequestStatus: RequestStatus.Success,
    applePaySupportFlagRequestError: null,
  })),
  on(invoiceActions.getApplePaySupportFlagFailure, (state, { error }) => ({
    ...state,
    applePaySupportFlag: null,
    applePaySupportFlagRequestStatus: RequestStatus.Failure,
    applePaySupportFlagRequestError: error,
  })),
  on(invoiceActions.resetApplePaySupportFlag, (state) => ({
    ...state,
    applePaySupportFlag: initialState.applePaySupportFlag,
    applePaySupportFlagRequestStatus:
      initialState.applePaySupportFlagRequestStatus,
    applePaySupportFlagRequestError:
      initialState.applePaySupportFlagRequestError,
  })),
  on(invoiceActions.clearPaymentAuthorizationRequest, (state) => ({
    ...state,
    paymentAuthorizationRequest: initialState.paymentAuthorizationRequest,
    paymentPlanAuthorizationRequest:
      initialState.paymentPlanAuthorizationRequest,
  })),
  on(invoiceActions.addInvoiceList, (state, { payload }) => ({
    ...state,
    selectedInvoiceList: payload,
  })),
  on(invoiceActions.clearInvoiceIdList, (state) => ({
    ...state,
    selectedInvoiceList: [],
  })),
  on(invoiceActions.addSelectedPaymentPlanOption, (state, { payload }) => ({
    ...state,
    selectedPaymentPlanOption: payload,
  })),
  on(invoiceActions.addEmail2TextPaymentPlanLink, (state, { payload }) => ({
    ...state,
    email2TextPaymentPlanLink: payload,
  })),
  on(invoiceActions.clearEmail2TextPaymentPlanLink, (state) => ({
    ...state,
    email2TextPaymentPlanLink: false,
  })),
  on(invoiceActions.saveOriginalInvoice, (state, { payload }) => ({
    ...state,
    saveOriginalInvoice: payload,
  })),
  on(invoiceActions.addBackToBillingFlag, (state, { payload }) => ({
    ...state,
    backToBillingFlag: payload,
  }))
);
